import { CommonModule, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { ImagePreviewComponent } from '@components/image-preview/image-preview.component';
import { MenuOfferButtonComponent } from '@components/menu-offer-button/menu-offer-button.component';
import { OfferButtonComponent } from '@components/offer-button/offer-button.component';

import { HeaderComponent } from '@pages/header/header.component';
import { FooterComponent } from '@pages/footer/footer.component';
import { PageNotFoundComponent } from '@pages/not-found/not-found.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localePl, 'pl');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    ImagePreviewComponent,
    MenuOfferButtonComponent,
    OfferButtonComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
  ],
  bootstrap: [AppComponent],
  providers: [Title, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
