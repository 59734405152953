<div class="mx-[5%] flex justify-between">
  <a class="h-[65px] flex items-center" href="/"
    ><img class="w-full" src="assets/kamadoor.jpg" alt="logo Kamadoor"
  /></a>
  <div class="flex gap-4 desktopSmall:hidden">
    <a
      class="flex desktopSmall:hidden items-center"
      href="https://www.facebook.com/kamadoor.poznan"
      aria-label="Facebook - KamaDoor Poznań"
    >
      <i
        class="text-2xl pi pi-facebook hover:text-kamadoor-orange active:text-kamadoor-orange"
      ></i> </a
    ><a
      class="flex desktopSmall:hidden items-center"
      href="https://www.instagram.com/kamadoor_poznan"
      aria-label="Instagram - KamaDoor Poznań"
    >
      <i
        class="text-2xl pi pi-instagram hover:text-kamadoor-orange active:text-kamadoor-orange"
      ></i>
    </a>
  </div>
  <div class="hidden desktopSmall:flex justify-between gap-4 my-2">
    <div class="flex flex-col">
      <a
        class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
        href="tel:+48606675923"
        aria-label="Telefon 1"
      >
        <i class="text-xl pi pi-phone"></i>
        <span class="text-right text-xl font-extrabold"> 606 675 923 </span>
      </a>

      <a
        class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
        href="mailto:sklep@kamadoor.pl"
        aria-label="Email"
      >
        <i class="text-xl pi pi-at"></i>
        <span class="text-right text-xl font-extrabold">
          sklep&#64;kamadoor.pl
        </span>
      </a>
    </div>
    <div class="flex flex-col">
      <a
        class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
        href="tel:+48662081045"
        aria-label="Telefon 2"
      >
        <i class="text-xl pi pi-phone"></i>
        <span class="text-right text-xl font-extrabold"> 662 081 045 </span>
      </a>

      <div class="flex h-full gap-4">
        <a
          class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
          href="https://www.facebook.com/kamadoor.poznan"
          aria-label="Facebook - KamaDoor Poznań"
        >
          <i class="text-xl pi pi-facebook"></i>
          <span class="text-right text-xl font-extrabold"> Facebook </span> </a
        ><a
          class="flex items-center gap-2 h-full hover:text-kamadoor-orange active:text-kamadoor-orange"
          href="https://www.instagram.com/kamadoor_poznan"
          aria-label="Instagram - KamaDoor Poznań"
        >
          <i class="text-xl pi pi-instagram"></i>
          <span class="text-right text-xl font-extrabold"> Instagram </span>
        </a>
      </div>
    </div>
  </div>
</div>
<p-menubar [model]="items">
  <ng-template pTemplate="item" let-item let-root="root">
    @if (item.url) {
    <a
      pRipple
      [ngClass]="{
        'active-item':
          activeItem === item.id || (item.id === 'offer' && isOfferItemSelected)
      }"
      [href]="item.url"
      [ariaLabel]="item.label"
      class="flex align-items-center p-menuitem-link"
    >
      <span [class]="item.icon + ' font-extrabold'"></span>
      <span class="ml-2">{{ item.label }}</span>
      @if (item.items) {
      <i class="pi pi-angle-down ml-2"></i>
      }
    </a>
    } @else {
    <div
      [ngClass]="{
        'active-item':
          activeItem === item.id || (item.id === 'offer' && isOfferItemSelected)
      }"
      class="flex align-items-center p-menuitem-link"
      [ariaLabel]="item.label"
    >
      <span [class]="item.icon + ' font-extrabold'"></span>
      <span class="ml-2">{{ item.label }}</span>
      @if (item.items) {
      <i class="pi pi-angle-down ml-2"></i>
      }
    </div>
    }
  </ng-template>
</p-menubar>
